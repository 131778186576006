// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.VideoGrid_div-video-grid__cPKQJ {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 8px;
  row-gap: 16px;
  margin-left: 8px;
  margin-right: 8px;
}

/* Brief view styles */
.VideoGrid_brief-view__xONDr {
  overflow: hidden;
}

.VideoGrid_brief-view__xONDr.VideoGrid_desktop__RpXSZ {
  grid-template-rows: repeat(1, auto); /* One row of auto height */
}

.VideoGrid_brief-view__xONDr.VideoGrid_mobile__PY9bG {
  grid-template-rows: repeat(3, auto); /* Three rows of auto height */
}

@media (min-width: 301px) and (max-width: 430px) {
  .VideoGrid_div-video-grid__cPKQJ {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media (min-width: 431px) and (max-width: 630px) {
  .VideoGrid_div-video-grid__cPKQJ {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 631px) and (max-width: 844px) {
  .VideoGrid_div-video-grid__cPKQJ {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 845px) and (max-width: 1060px) {
  .VideoGrid_div-video-grid__cPKQJ {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (min-width: 1061px) and (max-width: 1280px) {
  .VideoGrid_div-video-grid__cPKQJ {
    grid-template-columns: repeat(5, 1fr);
  }
}

@media (min-width: 1281px) and (max-width: 1550px) {
  .VideoGrid_div-video-grid__cPKQJ {
    grid-template-columns: repeat(6, 1fr);
  }
}

@media (min-width: 1551px) and (max-width: 1650px) {
  .VideoGrid_div-video-grid__cPKQJ {
    grid-template-columns: repeat(7, 1fr);
  }
}
`, "",{"version":3,"sources":["webpack://./src/Components/YoutubeView/YtPlaylistDnD/VideoGrid.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,qCAAqC;EACrC,eAAe;EACf,aAAa;EACb,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA,sBAAsB;AACtB;EACE,gBAAgB;AAClB;;AAEA;EACE,mCAAmC,EAAE,2BAA2B;AAClE;;AAEA;EACE,mCAAmC,EAAE,8BAA8B;AACrE;;AAEA;EACE;IACE,qCAAqC;EACvC;AACF;;AAEA;EACE;IACE,qCAAqC;EACvC;AACF;;AAEA;EACE;IACE,qCAAqC;EACvC;AACF;;AAEA;EACE;IACE,qCAAqC;EACvC;AACF;;AAEA;EACE;IACE,qCAAqC;EACvC;AACF;;AAEA;EACE;IACE,qCAAqC;EACvC;AACF;;AAEA;EACE;IACE,qCAAqC;EACvC;AACF","sourcesContent":[".div-video-grid {\n  display: grid;\n  grid-template-columns: repeat(4, 1fr);\n  column-gap: 8px;\n  row-gap: 16px;\n  margin-left: 8px;\n  margin-right: 8px;\n}\n\n/* Brief view styles */\n.brief-view {\n  overflow: hidden;\n}\n\n.brief-view.desktop {\n  grid-template-rows: repeat(1, auto); /* One row of auto height */\n}\n\n.brief-view.mobile {\n  grid-template-rows: repeat(3, auto); /* Three rows of auto height */\n}\n\n@media (min-width: 301px) and (max-width: 430px) {\n  .div-video-grid {\n    grid-template-columns: repeat(1, 1fr);\n  }\n}\n\n@media (min-width: 431px) and (max-width: 630px) {\n  .div-video-grid {\n    grid-template-columns: repeat(2, 1fr);\n  }\n}\n\n@media (min-width: 631px) and (max-width: 844px) {\n  .div-video-grid {\n    grid-template-columns: repeat(3, 1fr);\n  }\n}\n\n@media (min-width: 845px) and (max-width: 1060px) {\n  .div-video-grid {\n    grid-template-columns: repeat(4, 1fr);\n  }\n}\n\n@media (min-width: 1061px) and (max-width: 1280px) {\n  .div-video-grid {\n    grid-template-columns: repeat(5, 1fr);\n  }\n}\n\n@media (min-width: 1281px) and (max-width: 1550px) {\n  .div-video-grid {\n    grid-template-columns: repeat(6, 1fr);\n  }\n}\n\n@media (min-width: 1551px) and (max-width: 1650px) {\n  .div-video-grid {\n    grid-template-columns: repeat(7, 1fr);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"div-video-grid": `VideoGrid_div-video-grid__cPKQJ`,
	"brief-view": `VideoGrid_brief-view__xONDr`,
	"desktop": `VideoGrid_desktop__RpXSZ`,
	"mobile": `VideoGrid_mobile__PY9bG`
};
export default ___CSS_LOADER_EXPORT___;
