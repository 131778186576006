// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.left-list,
.right-list {
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
}

.item {
  /* Your item styles */
  border: 1px solid #ccc;
  padding: 10px;
  cursor: grab;
}`, "",{"version":3,"sources":["webpack://./src/Components/ChannelEdit/MuuriList.css"],"names":[],"mappings":"AAAA;;EAEE,aAAa;EACb,0BAA0B;EAC1B,SAAS;AACX;;AAEA;EACE,qBAAqB;EACrB,sBAAsB;EACtB,aAAa;EACb,YAAY;AACd","sourcesContent":[".left-list,\n.right-list {\n  display: grid;\n  grid-template-columns: 1fr;\n  gap: 10px;\n}\n\n.item {\n  /* Your item styles */\n  border: 1px solid #ccc;\n  padding: 10px;\n  cursor: grab;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
